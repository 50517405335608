import { createSlice } from '@reduxjs/toolkit';
import riconoscibilita1 from "../../assets/images/raster/brand_Awareness.jpg";
import identityImage from "../../assets/images/raster/Drivetostore.jpg";
import riconoscibilita from "../../assets/images/raster/OnlineEngagement.png";
import target1 from '../../assets/images/svgs/targetOne.svg';
import target2 from '../../assets/images/svgs/targetTwo.svg';
import target3 from '../../assets/images/svgs/targetThree.svg';


const initialState = {
  campaignName: '',
  startDate: null,
  endDate: null,
  budgetCampaign: null,
  brandName: null,
  subBrandName: null,
  competitor: null,
  product: null,
  productLink: null,
  category : '',
  mediaType: '',
  sellType: '',
  sellTypeCost: null,
  targetIdea: false,
  target: '',
  locationType: null,
  selectedLocations: [],
  pdvNumbers: 1,
  hyperlocalFile: null,
  gptResponse: {
    "client": "Kiko",
    "budget": 25000,
    "goals": [
      {
        "nome_obiettivo": "Aumento della riconoscibilità del marchio",
        "percentuale_budget": 40.0,
        "budget": 10000.0,
        "target": [
          {
            "nome": "Giovani Fashion Enthusiasts",
            "percentuale_budget": 40.0,
            "budget": 4000.0,
            "ragione_scelta": "Gli utenti giovani amano essere aggiornati sulle ultime tendenze della moda e dei cosmetici e sono grandi consumatori di social media.",
            "analisi_psicodemografica": "Target di età 18-25 anni, prevalentemente donne, attivi sui social media, interessati a moda e bellezza."
          },
          {
            "nome": "Urban Professionals",
            "percentuale_budget": 40.0,
            "budget": 4000.0,
            "ragione_scelta": "Questo gruppo ha un alto potere d'acquisto e spesso cerca prodotti di bellezza di qualità per mantenere un'immagine professionale.",
            "analisi_psicodemografica": "Target di età 25-40 anni, sia uomini che donne, lavorano in ambienti urbani, interessati a cura personale e tendenze professionali."
          },
          {
            "nome": "Health and Wellness Enthusiasts",
            "percentuale_budget": 20.0,
            "budget": 2000.0,
            "ragione_scelta": "Questi utenti sono interessati alla cura del proprio corpo e alla bellezza, spesso cercano prodotti di qualità che offrano benefici per la pelle.",
            "analisi_psicodemografica": "Target di età 18-35 anni, prevalentemente donne, interessati a fitness, salute e bellezza."
          }
        ],
        "whitelist": [
          {
            "appname": "Subito.it"
          },
          {
            "appname": "Subito: compra e vendi vicino a te!"
          },
          {
            "appname": "3bmeteo.com"
          },
          {
            "appname": "3B Meteo"
          },
          {
            "appname": "AutoScout24 - used car finder"
          },
          {
            "appname": "AutoScout24: Buy & sell cars"
          },
          {
            "appname": "mobile.de - car market"
          },
          {
            "appname": "mobile.de ? Germany’s largest car market"
          },
          {
            "appname": "Vinted.fr"
          },
          {
            "appname": "Alight Motion — Video and Animation Editor "
          },
          {
            "appname": "Alarmy (Sleep If U Can)- alarm "
          },
          {
            "appname": "Alarmy - Alarm clock "
          },
          {
            "appname": "3D Model Editor for Minecraft "
          },
          {
            "appname": "3D Skins Maker for Minecraft "
          },
          {
            "appname": "3D Skin Editor for Minecraft "
          },
          {
            "appname": "3dtuning.com"
          },
          {
            "appname": "3DTuning "
          },
          {
            "appname": "AirDroid: Remote access & File "
          },
          {
            "appname": "All Video Downloader "
          },
          {
            "appname": "All Language Voice Translate "
          },
          {
            "appname": "All Language Translator, Voice "
          },
          {
            "appname": "All Language Translator "
          },
          {
            "appname": "All Language Translate App "
          },
          {
            "appname": "Alarm Clock Xtreme Free +Timer "
          },
          {
            "appname": "Alarm Clock For Me "
          },
          {
            "appname": "Alarm Clock "
          },
          {
            "appname": "Alarm Clock"
          },
          {
            "appname": "Addons - Mods for Minecraft PE "
          },
          {
            "appname": "AddOns Maker for Minecraft PE "
          },
          {
            "appname": "AddOns Maker Creator for Minecraft PE "
          },
          {
            "appname": "Addons Gun Mod for Minecraft "
          },
          {
            "appname": "Addons for Minecraft PE "
          },
          {
            "appname": "AeroWeather Lite "
          },
          {
            "appname": "AeroWeather "
          },
          {
            "appname": "Advanced Download Manager "
          },
          {
            "appname": "Ai Art Generator "
          },
          {
            "appname": "AndroVid Video Editor "
          },
          {
            "appname": "Aibi Photo: AI Photo Enhancer "
          },
          {
            "appname": "Aesthetic Skin for Minecraft PE "
          },
          {
            "appname": "Addons & Melon Playground Mods "
          },
          {
            "appname": "Adventure Sync - Pokewalk "
          },
          {
            "appname": "Advanced Security "
          },
          {
            "appname": "3D Modeling App: Sculpt & Draw "
          },
          {
            "appname": "4Craft : Addons for MCPE "
          },
          {
            "appname": "Add Watermark on Photos "
          },
          {
            "appname": "Alarm and pill reminder "
          },
          {
            "appname": "Alarm & Timer"
          },
          {
            "appname": "Alarm Clock ⏰ 😴 📢 "
          },
          {
            "appname": "Alarm Clock Beyond - Talking Alarm, Radio & Music "
          },
          {
            "appname": "Alarm Clock Plus★ "
          },
          {
            "appname": "Alarm Clock HD - Free "
          },
          {
            "appname": "All In One Calculator Multi Calc by CalcKit "
          },
          {
            "appname": "All Documents Reader: Documents Viewer "
          },
          {
            "appname": "All Document Scanner PDF Maker "
          },
          {
            "appname": "All Document Reader: Word, Excel, PDF, PPT & More"
          },
          {
            "appname": "All Document Reader: PDF, Word "
          },
          {
            "appname": "All Document Reader: PDF, excel, word, Documents"
          },
          {
            "appname": "All Document Reader and Viewer "
          },
          {
            "appname": "All Document Reader & Viewer "
          },
          {
            "appname": "All Document Reader - One Read "
          },
          {
            "appname": "All Document Reader - My PDF "
          },
          {
            "appname": "All Document Reader - Edit PDF "
          },
          {
            "appname": "All Code Scanner QR Reader App "
          },
          {
            "appname": "All Languages Translator app "
          },
          {
            "appname": "All Languages Translator - Free Voice Translation"
          },
          {
            "appname": "All Language Translator Free "
          },
          {
            "appname": "All Status Saver for WhatsApp - Status Downloader"
          },
          {
            "appname": "All Socials Video Downloader "
          },
          {
            "appname": "All Screen Video Cast Chromecast,DLNA,Roku,FireTV"
          },
          {
            "appname": "All Video Downloader App "
          },
          {
            "appname": "All Video Downloader 4K HD "
          },
          {
            "appname": "All Video Downloader 2018 "
          },
          {
            "appname": "All PDF-PDF Reader, View PDF "
          },
          {
            "appname": "All-In-One Calculator Free "
          },
          {
            "appname": "All-in-one Calculator "
          },
          {
            "appname": "Anagram Solver - Crosswords "
          },
          {
            "appname": "Anagram Solver "
          },
          {
            "appname": "Ampere "
          },
          {
            "appname": "Alfred Security Camera "
          },
          {
            "appname": "Anti Theft & Don't Touch Phone "
          },
          {
            "appname": "Anti Theft Alarm - Don't Touch My Phone "
          },
          {
            "appname": "Anti Theft Phone Alarm "
          },
          {
            "appname": "Anti Theft with Phone Alarm "
          },
          {
            "appname": "AntiTheft: Don't Touch Phone "
          },
          {
            "appname": "Antivirus Free 2021 - Virus Cleaner "
          },
          {
            "appname": "AntiVirus Security - FREE "
          },
          {
            "appname": "Antivirus: Virus Clean Master "
          },
          {
            "appname": "Antivirus-PhoneMaster "
          },
          {
            "appname": "App Hider - Hide apps "
          },
          {
            "appname": "App Lock "
          },
          {
            "appname": "App Lock - Applock Fingerprint "
          },
          {
            "appname": "App Lock - Fingerprint Applock "
          },
          {
            "appname": "App Lock - Fingerprint Lock "
          },
          {
            "appname": "App Lock - Lock Apps, Fingerprint & Password Lock "
          },
          {
            "appname": "App Lock - Lock Apps, Pattern "
          },
          {
            "appname": "App Lock & Guard - AppLock "
          },
          {
            "appname": "App Lock, Fingerprint Lock "
          },
          {
            "appname": "App Lock: Lock App, Fingerprint "
          },
          {
            "appname": "App Locker: Best AppLock "
          },
          {
            "appname": "Android Hidden Settings "
          }
        ]
      },
      {
        "nome_obiettivo": "Creazione di un'identità di marca forte",
        "percentuale_budget": 35.0,
        "budget": 8750.0,
        "target": [
          {
            "nome": "Luxury Beauty Seekers",
            "percentuale_budget": 40.0,
            "budget": 3500.0,
            "ragione_scelta": "Questi utenti sono alla ricerca di prodotti di alta qualità e marchi di lusso, spesso disposti a pagare un prezzo premium per prodotti di bellezza esclusivi.",
            "analisi_psicodemografica": "Target di età 25-45 anni, prevalentemente donne, con reddito medio-alto, interessati a prodotti di lusso e esclusivi."
          },
          {
            "nome": "Eco-Conscious Consumers",
            "percentuale_budget": 35.0,
            "budget": 3062.5,
            "ragione_scelta": "Questo gruppo è attento alla sostenibilità e cerca prodotti rispettosi dell'ambiente e cruelty-free.",
            "analisi_psicodemografica": "Target di età 18-35 anni, sia uomini che donne, interessati a sostenibilità, ambiente e prodotti cruelty-free."
          },
          {
            "nome": "Makeup Tutorials Fans",
            "percentuale_budget": 25.0,
            "budget": 2187.5,
            "ragione_scelta": "Questi utenti seguono tutorial di trucco e influencer nel settore della bellezza, costantemente alla ricerca di nuovi prodotti da provare.",
            "analisi_psicodemografica": "Target di età 16-30 anni, prevalentemente donne, attivi sui social media, interessati a tutorial di trucco e influencer di bellezza."
          }
        ],
        "whitelist": [
          {
            "appname": "Meteo - by iLMeteo.it"
          },
          {
            "appname": "The Weather - Weather forecast and widget"
          },
          {
            "appname": "AVG AntiVirus & Security"
          },
          {
            "appname": "Avast Antivirus - Scan & Remove Virus, Cleaner"
          },
          {
            "appname": "AccuWeather: Weather Alerts"
          },
          {
            "appname": "3B Meteo - Weather Forecasts"
          },
          {
            "appname": "CamScanner - PDF Scanner App Free"
          },
          {
            "appname": "CamScanner-PDF Scanner App"
          },
          {
            "appname": "Candy Crush Saga"
          },
          {
            "appname": "Corriere della Sera Mobile"
          },
          {
            "appname": "Duolingo - Language Lessons"
          },
          {
            "appname": "Duolingo: Learn Languages Free"
          },
          {
            "appname": "Eurosport"
          },
          {
            "appname": "Eurosport: Sports News, Results & Scores"
          },
          {
            "appname": "Flightradar24"
          },
          {
            "appname": "GialloZafferano: Italian Cooking Recipes"
          },
          {
            "appname": "GialloZafferano: le Ricette"
          },
          {
            "appname": "InShot - Video Editor"
          },
          {
            "appname": "La Gazzetta dello Sport"
          },
          {
            "appname": "LiveScore: Live Sports Scores"
          },
          {
            "appname": "Microsoft Outlook"
          },
          {
            "appname": "Microsoft Outlook: Organize Your Email & Calendar"
          },
          {
            "appname": "Muslim Pro - Prayer Times, Azan, Quran, Qibla compass & Hijri / Islamic calendar (?????? ?????? - ???? - ????? ??????)"
          },
          {
            "appname": "Muslim Pro - Quran, Athan, Prayer Times & Qibla"
          },
          {
            "appname": "Opera Browser: Fast & Private"
          },
          {
            "appname": "Picsart Photo Editor: Pic, Video & Collage Maker"
          },
          {
            "appname": "Quizlet: Learn with Flashcards"
          },
          {
            "appname": "Scanner App: PDF Document Scan"
          },
          {
            "appname": "Shpock: Buy & Sell"
          },
          {
            "appname": "Speedtest.net Speed Test"
          },
          {
            "appname": "Spotify New Music and Podcasts"
          },
          {
            "appname": "Spotify: Music and Podcasts"
          },
          {
            "appname": "Subito.it"
          },
          {
            "appname": "Subito: compra e vendi vicino a te!"
          },
          {
            "appname": "Tripadvisor Hotel, Flight & Restaurant Bookings"
          },
          {
            "appname": "Tripadvisor: Plan & Book Trips"
          },
          {
            "appname": "Weather & Radar - Storm alerts"
          },
          {
            "appname": "wetter.com"
          },
          {
            "appname": "3B Meteo"
          },
          {
            "appname": "365Scores: Live Scores & Sports News"
          },
          {
            "appname": "365Scores - Live Scores"
          },
          {
            "appname": "3bmeteo.com"
          },
          {
            "appname": "9GAG: Funny gifs, pics, fresh memes & viral videos"
          },
          {
            "appname": "9GAG: Best LOL Pics & GIFs"
          },
          {
            "appname": "8 Ball Pool"
          },
          {
            "appname": "4 Immagini 1 Parola "
          },
          {
            "appname": "4 Immagini 1 Parola"
          },
          {
            "appname": "4 Immagini - 1 Parola "
          },
          {
            "appname": "Akinator"
          },
          {
            "appname": "AccuWeather: Weather alerts & live forecast info"
          },
          {
            "appname": "Alarmy (Sleep If U Can)- alarm"
          },
          {
            "appname": "Alarmy (Sleep If U Can)- Alarm clock"
          },
          {
            "appname": "Amerigo"
          },
          {
            "appname": "AutoScout24: Buy & sell cars"
          },
          {
            "appname": "AutoScout24 - used car finder"
          },
          {
            "appname": "Astri di Paolo Fox"
          },
          {
            "appname": "B?rse & Aktien - finanzen.net"
          },
          {
            "appname": "BBC News"
          },
          {
            "appname": "BeSoccer - Soccer Live Score"
          },
          {
            "appname": "BeSoccer"
          },
          {
            "appname": "Bejeweled"
          },
          {
            "appname": "Biancocelesti News"
          },
          {
            "appname": "Breaking News: Local & Breaking"
          },
          {
            "appname": "Briefing"
          },
          {
            "appname": "Calciomercato.com"
          },
          {
            "appname": "Calciomercato.com 2016"
          },
          {
            "appname": "Calorie Counter - MyFitnessPal"
          },
          {
            "appname": "Candy Crush Friends Saga"
          },
          {
            "appname": "Candy Crush Jelly Saga"
          },
          {
            "appname": "Candy Crush Soda Saga"
          },
          {
            "appname": "CCleaner"
          },
          {
            "appname": "Chess - Play & Learn"
          },
          {
            "appname": "ClevCalc - Calculator"
          },
          {
            "appname": "Corriere dello Sport.it"
          },
          {
            "appname": "Corriere UP"
          },
          {
            "appname": "Crunchyroll"
          },
          {
            "appname": "Daily Mail Online"
          },
          {
            "appname": "De Telegraaf nieuws"
          },
          {
            "appname": "Diretta"
          },
          {
            "appname": "Dragon City Mobile"
          },
          {
            "appname": "Dream League Soccer 2020"
          },
          {
            "appname": "eBay Kleinanzeigen for Germany"
          },
          {
            "appname": "Empires & Puzzles: RPG Quest"
          },
          {
            "appname": "eSound Music"
          },
          {
            "appname": "Euronews: Daily breaking world news & Live TV"
          },
          {
            "appname": "Fanpage"
          },
          {
            "appname": "FIFA Soccer"
          },
          {
            "appname": "FileMaster: File Manage, File Transfer Power Clean"
          },
          {
            "appname": "FlashScore - live scores"
          },
          {
            "appname": "Flightradar24 Flight Tracker"
          },
          {
            "appname": "Flipboard - Latest News, Top Stories & Lifestyle"
          },
          {
            "appname": "Flipboard - Lifestyle and News"
          },
          {
            "appname": "FOCUS Online - Nachrichten: Die schnelle News App"
          },
          {
            "appname": "FOCUS Online Nachrichten"
          },
          {
            "appname": "Foot Mercato : Transferts"
          },
          {
            "appname": "Football Live Score Tv"
          },
          {
            "appname": "Football Strike: Online Soccer"
          },
          {
            "appname": "Football TV Live App"
          },
          {
            "appname": "Football TV Live Streaming HD - Live Football TV"
          },
          {
            "appname": "Forza Football - Live soccer scores"
          }
        ]
      },
      {
        "nome_obiettivo": "Miglioramento della percezione del marchio",
        "percentuale_budget": 25.0,
        "budget": 6250.0,
        "target": [
          {
            "nome": "Previous Competitor Customers",
            "percentuale_budget": 50.0,
            "budget": 3125.0,
            "ragione_scelta": "Ex clienti della concorrenza che hanno mostrato interesse online per i marchi concorrenti, ma potrebbero essere attratti da offerte o nuove formulazioni.",
            "analisi_psicodemografica": "Target di età 25-45 anni, sia uomini che donne, con interesse verso marchi concorrenti di cosmetici e bellezza."
          },
          {
            "nome": "Deal Seekers",
            "percentuale_budget": 30.0,
            "budget": 1875.0,
            "ragione_scelta": "Questi consumatori sono alla ricerca di buoni affari e promozioni, aperti a provare nuovi marchi se il prezzo è giusto.",
            "analisi_psicodemografica": "Target di età 18-35 anni, prevalentemente donne, interessati a sconti, promozioni e offerte speciali."
          },
          {
            "nome": "Beauty Bloggers",
            "percentuale_budget": 20.0,
            "budget": 1250.0,
            "ragione_scelta": "Utenti che seguono blog di bellezza e recensioni di prodotti di cosmetici, spesso influenzati dalle opinioni di esperti.",
            "analisi_psicodemografica": "Target di età 18-40 anni, prevalentemente donne, attivi sui social media, interessati a recensioni di prodotti e consigli di bellezza."
          }
        ],
        "whitelist": [
          {
            "appname": "Meteo - by iLMeteo.it"
          },
          {
            "appname": "The Weather - Weather forecast and widget"
          },
          {
            "appname": "AVG AntiVirus & Security"
          },
          {
            "appname": "Avast Antivirus - Scan & Remove Virus, Cleaner"
          },
          {
            "appname": "AccuWeather: Weather Alerts"
          },
          {
            "appname": "3B Meteo - Weather Forecasts"
          },
          {
            "appname": "CamScanner - PDF Scanner App Free"
          },
          {
            "appname": "CamScanner-PDF Scanner App"
          },
          {
            "appname": "Candy Crush Saga"
          },
          {
            "appname": "Corriere della Sera Mobile"
          },
          {
            "appname": "Duolingo - Language Lessons"
          },
          {
            "appname": "Duolingo: Learn Languages Free"
          },
          {
            "appname": "Eurosport"
          },
          {
            "appname": "Eurosport: Sports News, Results & Scores"
          },
          {
            "appname": "Flightradar24"
          },
          {
            "appname": "GialloZafferano: Italian Cooking Recipes"
          },
          {
            "appname": "GialloZafferano: le Ricette"
          },
          {
            "appname": "InShot - Video Editor"
          },
          {
            "appname": "La Gazzetta dello Sport"
          },
          {
            "appname": "LiveScore: Live Sports Scores"
          },
          {
            "appname": "Microsoft Outlook"
          },
          {
            "appname": "Microsoft Outlook: Organize Your Email & Calendar"
          },
          {
            "appname": "Muslim Pro - Prayer Times, Azan, Quran, Qibla compass & Hijri / Islamic calendar (?????? ?????? - ???? - ????? ??????)"
          },
          {
            "appname": "Muslim Pro - Quran, Athan, Prayer Times & Qibla"
          },
          {
            "appname": "Opera Browser: Fast & Private"
          },
          {
            "appname": "Picsart Photo Editor: Pic, Video & Collage Maker"
          },
          {
            "appname": "Quizlet: Learn with Flashcards"
          },
          {
            "appname": "Scanner App: PDF Document Scan"
          },
          {
            "appname": "Shpock: Buy & Sell"
          },
          {
            "appname": "Speedtest.net Speed Test"
          },
          {
            "appname": "Spotify New Music and Podcasts"
          },
          {
            "appname": "Spotify: Music and Podcasts"
          },
          {
            "appname": "Subito.it"
          },
          {
            "appname": "Subito: compra e vendi vicino a te!"
          },
          {
            "appname": "Tripadvisor Hotel, Flight & Restaurant Bookings"
          },
          {
            "appname": "Tripadvisor: Plan & Book Trips"
          },
          {
            "appname": "Weather & Radar - Storm alerts"
          },
          {
            "appname": "wetter.com"
          },
          {
            "appname": "3B Meteo"
          },
          {
            "appname": "365Scores: Live Scores & Sports News"
          },
          {
            "appname": "365Scores - Live Scores"
          },
          {
            "appname": "3bmeteo.com"
          },
          {
            "appname": "9GAG: Funny gifs, pics, fresh memes & viral videos"
          },
          {
            "appname": "9GAG: Best LOL Pics & GIFs"
          },
          {
            "appname": "8 Ball Pool"
          },
          {
            "appname": "4 Immagini 1 Parola "
          },
          {
            "appname": "4 Immagini 1 Parola"
          },
          {
            "appname": "4 Immagini - 1 Parola "
          },
          {
            "appname": "Akinator"
          },
          {
            "appname": "AccuWeather: Weather alerts & live forecast info"
          },
          {
            "appname": "Alarmy (Sleep If U Can)- alarm"
          },
          {
            "appname": "Alarmy (Sleep If U Can)- Alarm clock"
          },
          {
            "appname": "Amerigo"
          },
          {
            "appname": "AutoScout24: Buy & sell cars"
          },
          {
            "appname": "AutoScout24 - used car finder"
          },
          {
            "appname": "Astri di Paolo Fox"
          },
          {
            "appname": "B?rse & Aktien - finanzen.net"
          },
          {
            "appname": "BBC News"
          },
          {
            "appname": "BeSoccer - Soccer Live Score"
          },
          {
            "appname": "BeSoccer"
          },
          {
            "appname": "Bejeweled"
          },
          {
            "appname": "Biancocelesti News"
          },
          {
            "appname": "Breaking News: Local & Breaking"
          },
          {
            "appname": "Briefing"
          },
          {
            "appname": "Calciomercato.com"
          },
          {
            "appname": "Calciomercato.com 2016"
          },
          {
            "appname": "Calorie Counter - MyFitnessPal"
          },
          {
            "appname": "Candy Crush Friends Saga"
          },
          {
            "appname": "Candy Crush Jelly Saga"
          },
          {
            "appname": "Candy Crush Soda Saga"
          },
          {
            "appname": "CCleaner"
          },
          {
            "appname": "Chess - Play & Learn"
          },
          {
            "appname": "ClevCalc - Calculator"
          },
          {
            "appname": "Corriere dello Sport.it"
          },
          {
            "appname": "Corriere UP"
          },
          {
            "appname": "Crunchyroll"
          },
          {
            "appname": "Daily Mail Online"
          },
          {
            "appname": "De Telegraaf nieuws"
          },
          {
            "appname": "Diretta"
          },
          {
            "appname": "Dragon City Mobile"
          },
          {
            "appname": "Dream League Soccer 2020"
          },
          {
            "appname": "eBay Kleinanzeigen for Germany"
          },
          {
            "appname": "Empires & Puzzles: RPG Quest"
          },
          {
            "appname": "eSound Music"
          },
          {
            "appname": "Euronews: Daily breaking world news & Live TV"
          },
          {
            "appname": "Fanpage"
          },
          {
            "appname": "FIFA Soccer"
          },
          {
            "appname": "FileMaster: File Manage, File Transfer Power Clean"
          },
          {
            "appname": "FlashScore - live scores"
          },
          {
            "appname": "Flightradar24 Flight Tracker"
          },
          {
            "appname": "Flipboard - Latest News, Top Stories & Lifestyle"
          },
          {
            "appname": "Flipboard - Lifestyle and News"
          },
          {
            "appname": "FOCUS Online - Nachrichten: Die schnelle News App"
          },
          {
            "appname": "FOCUS Online Nachrichten"
          },
          {
            "appname": "Foot Mercato : Transferts"
          },
          {
            "appname": "Football Live Score Tv"
          },
          {
            "appname": "Football Strike: Online Soccer"
          },
          {
            "appname": "Football TV Live App"
          },
          {
            "appname": "Football TV Live Streaming HD - Live Football TV"
          },
          {
            "appname": "Forza Football - Live soccer scores"
          }
        ]
      }
    ]
  },
  gptEditedResponse: null,
  gptIndex: 0,
  mediaTypeList : [],
  hasDN: 'false',
  sellTypeList: [],
  minMaxSellType: null,
  tempId: "b102d104-b4f1-484c-b6c7-5c68e0b0d99f",
  kpiDescription: null,
  strategyObjective: [],
  contentText : [
    {id:"Brand Awareness", title: "Brand Awareness", icon: target1 , image: riconoscibilita1, description: "Rafforzare la visibilità e il riconoscimento del brand presso un vasto pubblico, utilizzando targeting avanzato e formati pubblicitari efficaci"},
    {id:"Drive-to-Store", title:"Drive2Store",  icon: target2 , image:identityImage , description:"Incentivare le visite ai punti vendita fisici collegando la pubblicità mobile al traffico locale, spingendo gli utenti a interagire direttamente con il brand"},
    {id:"Online Engagement", title:"Online Engagement", icon:target3  , image:riconoscibilita , description:"Stimolare interazioni online da parte di utenti qualificati, ottimizzando l'engagement su app e piattaforme digitali"},
  ],
};

export const TargetFinderSlice = createSlice({
  name: 'targetFinder',
  initialState,
  reducers: {
    setCampaignName: (state, action) => {
      state.campaignName = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setBudgetCampaign: (state, action) => {
      state.budgetCampaign = action.payload;
    },
    setBrandName: (state, action) => {
      state.brandName = action.payload;
    },
    setSubBrandName: (state, action) => {
      state.subBrandName = action.payload;
    },
    setCompetitor: (state, action) => {
      state.competitor = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setProductLink: (state, action) => {
      state.productLink = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setMediaType: (state, action) => {
      state.mediaType = action.payload;
    },
    setSellType: (state, action) => {
      state.sellType = action.payload;
    },
    setSellTypeCost: (state, action) => {
      state.sellTypeCost = action.payload;
    },
    setTargetIdea: (state, action) => {
      state.targetIdea = action.payload;
    },
    setTarget: (state, action) => {
      state.target = action.payload;
    },
    setLocationType: (state, action) => {
      state.locationType = action.payload;
    },
    setSelectedLocations: (state, action) => {
      state.selectedLocations = action.payload;
    },
    setHyperlocalFile: (state, action) => {
      state.hyperlocalFile = action.payload;
    },
    setGptResponse: (state, action) => {
      state.gptResponse = action.payload;
    },
    setGptEditedResponse: (state, action) => {
      state.gptEditedResponse = action.payload;
    },
    setGptIndex: (state, action) => {
      state.gptIndex = action.payload;
    },
    setMediaTypeList: (state, action) => {
      state.mediaTypeList = action.payload;
    },
    setSellTypeList: (state, action) => {
      state.sellTypeList = action.payload;
    },
    setMinMaxSellType: (state, action) => {
      state.minMaxSellType = action.payload;
    },
    setHasDN: (state, action) => {
      state.hasDN = action.payload;
    },
    setPdvNumbers: (state, action) => {
      state.pdvNumbers = action.payload;
    },
    setTempId: (state, action) => {
      state.tempId = action.payload;
    },
    setKpiDescription: (state, action) => {
      state.kpiDescription = action.payload;
    },
    setStrategyObjective: (state, action) => {
      state.strategyObjective = action.payload
    },
  },
});

export const {
  setStartDate,
  setEndDate,
  setCampaignName,
  setBudgetCampaign,
  setBrandName,
  setSubBrandName,
  setCompetitor,
  setProduct,
  setProductLink,
  setCategory,
  setMediaType,
  setSellType,
  setSellTypeCost,
  setTargetIdea,
  setTarget,
  setLocationType,
  setSelectedLocations,
  setHyperlocalFile,
  setGptResponse,
  setGptEditedResponse,
  setGptIndex,
  setMediaTypeList,
  setSellTypeList,
  setMinMaxSellType,
  setHasDN,
  setPdvNumbers,
  setTempId,
  setKpiDescription,
  setStrategyObjective
  
  
} = TargetFinderSlice.actions;


export default TargetFinderSlice.reducer;
